import React, { useState } from 'react';
import styled from 'styled-components';
import { ClientDetail } from '../../types/adminPortalApiSchema';
import { StyledBlueButton, StyledRedButton } from '../Button/Button';
import { Heading2 } from '../Theme/Theme';
import FollowerRelationshipModal from '../FollowerRelationshipModal/FollowerRelationshipModal';

export type FollowMutation = 'follow' | 'unfollow';

export type FollowAction = {
  title: string;
  instructions: string;
  followeeUserIds?: string[];
  followerUserIds?: string[];
  mutation: FollowMutation;
};

type Props = {
  userId: ClientDetail['id'];
};

const CommunitySection: React.FC<Props> = ({ userId }) => {
  const [followAction, setFollowAction] = useState<FollowAction | null>(null);

  return (
    <CommunitySectionWrapper>
      <HeaderSection>
        <Heading2>Community</Heading2>
        <p>
          A user will see the activity of other users they follow on their
          community feed. Use this section to change who this user follows and
          who follows them.
        </p>
      </HeaderSection>

      <FollowersWrapper>
        <FollowSection>
          <p>Who follows this user</p>
          <ButtonContainer>
            <StyledBlueButton
              data-testid={'add-followers-button'}
              onClick={() =>
                setFollowAction({
                  title: 'Add followers',
                  instructions:
                    'Enter the IDs of the users you want to follow this user',
                  followeeUserIds: [userId],
                  mutation: 'follow',
                })
              }
            >
              Add followers
            </StyledBlueButton>
            <StyledRedButton
              data-testid={'remove-followers-button'}
              onClick={() =>
                setFollowAction({
                  title: 'Remove followers',
                  instructions:
                    'Enter the IDs of the users you want to stop following this user',
                  followeeUserIds: [userId],
                  mutation: 'unfollow',
                })
              }
            >
              Remove followers
            </StyledRedButton>
          </ButtonContainer>
        </FollowSection>
        <Divider />
        <FollowSection>
          <p>Who this user follows</p>
          <ButtonContainer>
            <StyledBlueButton
              data-testid={'follow-other-users-button'}
              onClick={() => {
                setFollowAction({
                  title: 'Follow other users',
                  instructions:
                    'Enter the IDs of the users you want this user to follow',
                  followerUserIds: [userId],
                  mutation: 'follow',
                });
              }}
            >
              Follow other users
            </StyledBlueButton>
            <StyledRedButton
              data-testid={'unfollow-other-users-button'}
              onClick={() => {
                setFollowAction({
                  title: 'Unfollow other users',
                  instructions:
                    'Enter the IDs of the users you want to stop this user from following',
                  followerUserIds: [userId],
                  mutation: 'unfollow',
                });
              }}
            >
              Unfollow other users
            </StyledRedButton>
          </ButtonContainer>
        </FollowSection>
      </FollowersWrapper>
      <FollowerRelationshipModal
        followAction={followAction}
        setFollowAction={setFollowAction}
      />
    </CommunitySectionWrapper>
  );
};

export default CommunitySection;

const CommunitySectionWrapper = styled.div`
  padding: 50px;
  gap: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const FollowersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const FollowSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.grey};
  margin: 20px 0;
`;
